import React, { FC, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import {
    FlexRow,
    NewReactContentContainer,
    ReactPageContainer,
    SkipLink,
} from 'app/common/components/ReactPage.styled';

import { TaskProvider } from 'app/common/contexts/taskListener';
import { WebsocketConnectionProvider } from 'app/common/contexts/websocketConnection';

import { useIsConversation } from 'app/pages/conversations/Messaging/hooks/useIsConversation';

const ReactPage = ({ children }: PropsWithChildren<object>) => {
    const { t } = useTranslation();
    const isConversation = useIsConversation();
    const applyNewBackground = !isConversation;

    return (
        <>
            <SkipLink href="#page-content" variant="primary">
                {t('skip_link')}
            </SkipLink>
            <FlexRow>
                <ReactPageContainer id="react-page" applyNewBackground={applyNewBackground}>
                    <NewReactContentContainer id="react-content">
                        {children}
                    </NewReactContentContainer>
                </ReactPageContainer>
            </FlexRow>
        </>
    );
};

// A helper HOC to put WebsocketConnectionProvider above the consumer hook below
const withWebsocketConnection =
    <P extends JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<P>): FC<P> =>
    props => (
        <WebsocketConnectionProvider>
            <WrappedComponent {...props} />
        </WebsocketConnectionProvider>
    );

// HOC to catch task updates based on websocket events above
const withJobEventsListner =
    <P extends JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<P>): FC<P> =>
    props => (
        <TaskProvider>
            <WrappedComponent {...props} />
        </TaskProvider>
    );

export default compose(withWebsocketConnection, withJobEventsListner)(ReactPage);
