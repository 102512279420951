import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import api from 'app/api/v2/api_calls';
import { PrivacyPolicyUrlParams } from 'app/pages/privacyPolicy/types';

export const useMessagingPrivacyPolicy = () => {
    const { id } = useParams<PrivacyPolicyUrlParams>();

    return useQuery(
        ['privacyPolicyInfo', id],
        () => api.privacyPolicy.getMessagingPrivacyPolicy(id!),
        // Retries are prevented in order to avoid a delay for 404 redirection
        { enabled: !!id, retry: false },
    );
};
