import { FC, useState } from 'react';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';

import {
    FBLoginCallbackAuthResponse,
    launchEmbeddedSignup,
} from 'app/pages/settingsV2/subPages/Messages/components/Whatsapp/utils/launchEmbeddedSignup';

import { WhatsappAccountImportErrorPage } from './error';
import { WhatsappAccountRequirementsPage } from './requirements';
import { WhatsappAccountImportSuccessPage } from './success';

export const WhatsappAccountImportPage: FC = () => {
    const [isSignupSuccess, setIsSignupSuccess] = useState<boolean | null>(null);

    const { data: me } = useMe();
    const { data: org } = useOrganization(me?.org_id!, !!me);
    const readyForSignup = !!(me && org);

    const handleSignupSuccess = (response: FBLoginCallbackAuthResponse) => {
        setIsSignupSuccess(true);
        window.open(`partoo://whatsapp/success/${response.code}/${response.user_id}`, '_self');
    };

    const handleSignupError = () => {
        setIsSignupSuccess(false);
        window.open('partoo://whatsapp/error', '_self');
    };

    const handleRequirementsButtonClicked = () => {
        launchEmbeddedSignup(handleSignupSuccess, handleSignupError, false, org!.name);
    };

    if (isSignupSuccess) {
        return <WhatsappAccountImportSuccessPage />;
    } else if (isSignupSuccess == false) {
        return <WhatsappAccountImportErrorPage />;
    } else {
        return (
            <WhatsappAccountRequirementsPage
                onButtonClick={handleRequirementsButtonClicked}
                readyForSignup={readyForSignup}
            />
        );
    }
};
